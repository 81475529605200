import * as React from "react"
import { Helmet } from "react-helmet"
import "@fontsource/kanit"

const IndexPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'th',
        }}
      >
        <title>Warat K.</title>
        <meta property='og:image' content='/og-banner.png' />
        <meta property='og:locale' content='th_TH' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Warat Kaweepornpoj' />
        <meta property='og:description' content="ทั้งทรงพลัง ทั้งสดใส ทั้งน่ามหัศจรรย์" />
        <meta property='og:url' content='https://warat.win' />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <div className="w-full fixed h-14 navbar flex items-center z-10">
        <div className="m-auto w-full xl:max-w-screen-lg lg:max-w-screen-md md:max-w-screen-sm max-w-xxs flex justify-between items-center">
          <div className="text-white font-bold md:text-2xl text-xl">
            Warat K.
          </div>
          <div>
            <BlueButton text="English" link="/" />
          </div>
        </div>
      </div>
      <div className="min-h-screen w-full bg-black flex flex-col items-center justify-center pt-24 pb-16 md:pt-18">
        <div className="flex-2" />
        <video className="m-auto flex-2 flex-grow-0" width="640" height="480" autobuffer="true" preload="true" autoPlay muted>
          <source type='video/mp4' src="/video.mp4"></source>
        </video>
        <div className="container mx-auto max-w-4xl flex-1 text-center">
          <p className="text-gray-50 text-3xl font-bold m-6">Warat Kaweepornpoj</p>
          <p className="text-gray-50 md:text-7xl text-6xl font-black m-6 md:leading-tight leading-tight">ทั้งทรงพลัง<br />ทั้งน่ามหัศจรรย์</p>
          <p className="text-gray-50 text-xl m-6">
            วินคนนี้ออกแบบมาสำหรับท้าทายขีดจำกัดต่างๆ และพร้อมจะเปลี่ยนแปลงโลกใบนี้
            ด้วยประสิทธิภาพสมอง 8.6 หมื่นล้านเซลล์ที่ล้ำหน้าที่สุดเท่าที่เคยมีมา, จอประสาทตาแบบ Retina ที่เต็มอิ่มสมจริง และพื้นที่กระเพาะอาหารขนาดมหึมา
            บอกเลยว่านี่คือสุดยอดวินที่ถูกยกระดับให้เหนือชั้นไปอีกขั้น
          </p>

        </div>
      </div>
      <div className="min-h-screen w-full bg-black flex flex-col">
        <a href="https://www.linkedin.com/in/waratkawee/" title="linkedin" target="_blank" className="text-gray-50 md:text-3xl text-2xl text-center hover:underline">
          ดูโปรไฟล์บน LinkedIn >
        </a>
        <div className="container mx-auto px-3 max-w-screen-xl m-28">
          <div className="rounded-3xl h-auto p-10 md:my-5 my-3 bg-gradient-to-br from-pink-800 to-gray-900">
            <div className="grid md:grid-cols-12 grid-rows-none auto-rows-max">
              <div className="md:col-span-6">
                <div className="text-gray-50 text-xl font-bold">ประสิทธิภาพการทำงาน</div>
                <div className="text-gray-50 md:text-7xl text-4xl font-bold mt-20">รังสรรค์ซอฟท์แวร์</div>
                <div className="text-gray-50 md:text-7xl text-4xl font-bold">ด้วยกาแฟและเบียร์</div>
                <div className="text-gray-100 text-xl my-6">
                  อาหารและเครื่องดื่มดีๆ ขับเคลื่อนวรัตม์ให้เร็วและทรงพลัง ไม่ว่าจะทำเว็บ ทำเกม หรืออะไรก็ตามที่ไม่ใช่การเขียนเทส ก็พร้อมทำงานในทันที
                  และยังทำสิ่งที่ทำเป็นประจำอยู่ทุกวันอย่างการไถ Facebook, Twitter และ Instagram ได้เร็วขึ้น
                </div>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6 gap-3">
            <div className="rounded-3xl h-auto p-10 bg-gradient-to-b from-red-900 to-gray-900">
              <div className="text-gray-50 text-xl font-bold">ประสบการณ์</div>
              <img width="64" height="64" src="/icon-web-programming.png" alt="Software Engineer" className="w-16 mt-16" />
              <div className="text-gray-100 md:text-2xl text-xl my-4">
                วิศวกรซอฟต์แวร์
                <br /><span className="text-gray-400">พฤศจิกายน 2018 - ปัจจุบัน</span>
                <br />Agoda Services Co., Ltd.
              </div>
              <img width="64" height="64" src="/icon-rubik.png" alt="Game Designer" className="w-16 mt-16" />
              <div className="text-gray-100 md:text-2xl text-xl my-4">
                เกมดีไซน์เนอร์
                <br /><span className="text-gray-400">มิถุนายน 2018 - ตุลาคม 2018</span>
                <br />True Axion Interactive
              </div>
            </div>
            <div className="rounded-3xl flex flex-col h-auto p-10 bg-gradient-to-b from-indigo-900 to-gray-900">
              <div className="text-gray-50 text-xl font-bold self-start">ทักษะและความรู้</div>
              <div className="text-gray-100 text-xl mt-6">
                จะใช้งานจากทักษะและความรู้ที่มีอยู่แล้ว หรือใช้งานในสิ่งที่ไม่เคยรู้มาก่อนก็ไม่หวั่น ของอย่างนี้ขื้นกับว่าคุณจ่ายเท่าไหร่
              </div>
              <div className="md:w-10/12 mx-auto md:my-0 my-8 h-full flex flex-col justify-center">
                <img src="/app_collection.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto max-w-screen-xl md:my-20 my-none">
          <div className="text-gray-300 md:text-8xl text-4xl text-center md:mb-6 mb-2">พัฒนาอย่างต่อเนื่อง</div>
          <div className="text-gray-100 md:text-8xl text-4xl text-center font-bold mb-28">เติบโตแบบก้าวกระโดด</div>
          <div className="grid grid-flow-row auto-rows-max">
            <div className="grid grid-cols-12">
              <div className="col-start-3 col-span-6 my-4">
                <div className="bg-yellow-300 h-3 rounded-xl" />
                <div className="text-gray-50 mt-2 mb-6">Warat Kaweepornpoj ในปี 2023</div>
              </div>
              <div className="col-start–10 col-span-3">
                <div className="text-gray-50 text-xl text-left md:pl-8 pl-4">
                  อายุเพิ่มขื้นเป็น <p className="md:text-8xl text-4xl font-black">27 ปี</p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-start-3 col-span-4 my-4">
                <div className="bg-yellow-500 h-3 rounded-xl" />
                <div className="text-gray-50 mt-2 mb-6">Warat Kaweepornpoj ในปี 2022</div>
              </div>
              <div className="col-start–7 col-span-4 my-1">
                <div className="text-gray-50 text-2xl text-left pl-5">
                  อายุ 26 ปี
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-start-3 col-span-2 my-4">
                <div className="bg-yellow-600 h-3 rounded-xl" />
                <div className="text-gray-50 mt-2 mb-6">Warat Kaweepornpoj ในปี 2021</div>
              </div>

              <div className="col-start–7 col-span-4 my-1">
                <div className="text-gray-50 text-2xl text-left pl-5">
                  อายุ 25 ปี
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto max-w-screen-xl m-20">
          <div className="grid md:grid-cols-3 md:my-3">
            <div className="rounded-3xl p-10 pb-20 mx-3 mb-3 bg-apple-gray">
              <div className="text-gray-50 text-xl font-bold">พิมพ์เร็ว</div>
              <div className="h-full flex flex-col content-center justify-center pt-2">
                <div>
                  <div className="text-gray-50 text-8xl m-auto text-center font-bold">
                    97
                  </div>
                  <div className="text-gray-50 text-3xl mt-4 text-center font-bold">
                    คำต่อนาที (WPM)
                  </div>
                  <div className="text-gray-50 text-3xl mt-2 text-center">
                    หลับตาพิมพ์ได้
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-3xl p-10 pb-20 mx-3 mb-3 bg-apple-gray">
              <div className="text-gray-50 text-xl font-bold">ทักษะภาษาแบบวัดผล</div>
              <div className="text-gray-50 text-3xl mt-16 text-center">
                คะแนนโทอิกที่<br />หมดอายุไปแล้ว
              </div>
              <div className="text-gray-50 text-8xl mt-6 text-center font-bold">
                955
              </div>
              <div className="text-gray-50 text-lg mt-3 text-center">
                สอบไว้นานแล้ว ไม่ได้ใช้เลย<sup>1</sup>
              </div>
            </div>
            <div className="rounded-3xl p-10 mx-3 mb-3 bg-apple-gray">
              <div className="text-gray-50 text-xl font-bold">ภาษาที่ใช้สื่อสาร</div>
              <div className="h-full flex flex-col content-center justify-center pt-8">
                <div>
                  <div className="text-gray-50 text-6xl text-center font-bold">
                    ภาษาไทย
                  </div>
                  <div className="text-gray-50 mt-8 mb-16 text-6xl text-center font-bold">
                    English
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-3">
            <div className="rounded-3xl p-10 pb-20 mx-3 mb-3 bg-apple-gray bg-gradient-to-b from-yellow-900 to-gray-900">
              <div className="text-gray-50 text-xl font-bold">เครื่องดื่มสตาร์บัคส์ที่ชอบ</div>
              <div className="h-full flex flex-col content-center justify-center">
                <div className="text-gray-50 md:text-6xl text-4xl md:mt-0 mt-8 text-center font-bold md:leading-tight">
                  ออเรนจิน่า เพิ่มเอสเพรสโซ่ช็อต<sup>2</sup>
                </div>
              </div>
            </div>
            <div className="rounded-3xl md:col-span-2 p-10 pb-20 mx-3 mb-3 bg-gradient-to-b from-green-900 to-gray-900">
              <div className="text-gray-50 text-xl font-bold">ใช้งานได้ตลอดวัน</div>
              <div className="md:text-6xl text-4xl mt-16 text-center font-bold md:leading-tight text-green-300">
                อดหลับอดนอน<br />ได้นานสูงสุด<br />20 ชั่วโมง<br />นับว่านานแล้วแหละ<br />อย่าให้ข้ามวันเลย
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto max-w-screen-lg md:p-20 p-12">
          <div className="text-gray-400 md:text-8xl text-4xl md:mb-6 mb-2 font-bold">โปรเจคดีๆ</div>
          <div className="text-gray-100 md:text-8xl text-4xl font-bold mb-12 font-bold">มีให้ดูจนตาแฉะ<sup>3</sup></div>
          <div className="text-gray-400 text-3xl max-w-4xl leading-tight">
            พบกับโปรเจคอันยอดเยี่ยม ที่ได้รับคำชมจากตัวเอง
            <br />ตั้งแต่นวัตกรรมเปลี่ยนโลกจนถึงเกมที่จะทำให้คุณอารมณ์ดีไปทั้งวัน
          </div>
        </div>
        <div className="container md:max-w-screen-xl md:h-192 h-64 m-auto">
          <div className="macbook-bg w-10/12 md:max-h-192 h-full m-auto">

          </div>
        </div>

        <div className="container mx-auto max-w-screen-xl mb-20">
          <div className="grid md:grid-cols-2 md:gap-0 md:gap-y-3 sm:gap-3">
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-gradient-to-br from-gray-500 to-gray-800 relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">TABOO: คำต้องห้าม</span>
                <br /><span className="text-gray-400">ประเภท: เกม/แอพพลิเคชั่น</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                เกมคำต้องห้ามในเวอร์ชั่นมือถือ สร้างโดยใช้ React Native
                <br/>ได้รับแรงบันดาลใจจากช่องยูทูป <a href="https://www.youtube.com/@thepleelachannel" target="_blank">เทพลีลา</a>
              </div>
              <Button text="แอพสโตร์" link="https://apps.apple.com/th/app/taboo-%E0%B8%84%E0%B8%B3%E0%B8%95-%E0%B8%AD%E0%B8%87%E0%B8%AB-%E0%B8%B2%E0%B8%A1/id6443472967" />
              <Button text="เพลย์สโตร์" link="https://play.google.com/store/apps/details?id=com.pubstagames.taboo" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-crowdabout relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">CROWDABOUT</span>
                <br /><span className="text-gray-400">ประเภท: เกม</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                เกมสไตล์มินิมอลอาร์เขต เล่นได้บนมือถือ สร้างโดยใช้เอนจิ้น Unity 
              </div>
              <Button text="แอพสโตร์" link="https://bit.ly/crowdaboutios" />
              <Button text="เพลย์สโตร์" link="https://bit.ly/crowdaboutandroid" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-pokero relative">
              <img src="/icon-web-programming.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">POKERO</span>
                <br /><span className="text-gray-400">ประเภท: เว็บ</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                เว็บไซต์สำหรับใช้ในการทำสปริ้นแพลนนิ่ง
              </div>
              <Button text="Visit" link="https://pokercardestimate.web.app" />
              <Button text="Github" link="https://github.com/winwanwon/pokero" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-annex relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">ANNEX (2021)</span>
                <br /><span className="text-gray-400">ประเภท: เกม</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                เกม Twin-stick Shooter ผลงานจาก GMTK Game Jam 2021 ในธีม Joined Together
              </div>
              <Button text="เล่นเกม" link="https://winwanwon.itch.io/annex" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-44 relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">4/4 (2020)</span>
                <br /><span className="text-gray-400">ประเภท: เกม</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                เกม Puzzle ขนาดเล็ก ผลงานจาก GMTK Game Jam 2020 ในธีม Out of control
              </div>
              <Button text="เล่นเกม" link="https://winwanwon.itch.io/44" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-shitaro relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">SHITARO (2020)</span>
                <br /><span className="text-gray-400">ประเภท: เกม</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                เกม 2D Side-scrolling เกี่ยวกับแมวซ่อมยานอวกาศ ผลงานจาก Global Game Jam 2020 ในธีม Repair
              </div>
              <Button text="เล่นเกม" link="https://globalgamejam.org/2020/games/shitaro-1" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-gradient-to-br from-blue-900 to-gray-900 relative">
              <img src="/comment.png" alt="AI" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">HPChat (2018)</span>
                <br /><span className="text-gray-400">ประเภท: AI</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                แชทบอทสำหรับตอบคำถามผู้ใช้บริการโรงพยาบาล ได้รับรางวัลชนะเลิศจากการแข่งขัน NSC ในหมวด AI
              </div>
              <Button text="อ่านบล็อก" link="https://medium.com/@winwanwon/the-making-of-hpchat-7d43ee2e7810" />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-black bg-opacity-95 flex flex-col py-16 px-8">
        <div className="grid md:grid-cols-9 gap-y-16 grid-cols-2 text-gray-50 text-xl text-center leading-loose ">
          <Social
            isStart={true}
            text="กิตฮับ"
            linkText="แวะชม"
            icon="github"
            link="https://www.github.com/winwanwon"
          />
          <Social
            text="ทวิตเตอร์"
            linkText="ไถทวิต"
            icon="twitter"
            link="https://www.twitter.com/winwanwon"
          />
          <Social
            text="อินสตาแกรม"
            linkText="ส่องรูป"
            icon="instagram"
            link="https://www.instagram.com/winwanwon"
          />
          <Social
            text="มีเดียม"
            linkText="ตามไปอ่าน"
            icon="medium"
            link="https://www.medium.com/@winwanwon"
          />
          <Social
            text="ลิงค์อิน"
            linkText="อ๋อจ้างสิ"
            icon="linkedin"
            link="https://www.linkedin.com/in/waratkawee"
          />
        </div>
        <div className="container mx-auto border-b opacity-20 my-16" />
        <div className="container text-white opacity-40 text-sm mx-auto max-w-screen-lg">
          <p>1. สอบไว้เมื่อเดือนตุลาคม ปี 2017 ได้คะแนนมาก็ไม่รู้จะเอาไปทำอะไร</p>
          <p>2. ถ้ามีอย่างอื่นจัดโปรฯ อยู่ ก็จะชอบอันนั้นแทน</p>
          <p>3. ก็คือร้องไห้ นี่เรียกว่าดีแล้วหรอ</p>
        </div>
        <div className="m-auto pt-8">
          <a href="https://webring.wonderful.software#warat.win" title="วงแหวนเว็บ">
            <img
              alt="วงแหวนเว็บ"
              width="24"
              height="24"
              src="/webring.white.svg"
            />
          </a>
        </div>
      </div>
    </>
  )
}

const Social = ({ text, linkText, icon, link, isStart }) => {
  return (
    <div className={`${isStart && 'md:col-start-3'} col-span-1`}>
      <img width="32" height="32" src={`/${icon}.png`} alt={`${icon}`} className="w-8 h-8 opacity-60 mx-auto mb-3" />
      {text}
      <a href={link} title="linkedin" target="_blank" className="text-sm mt-2 block text-blue-400 hover:underline">{linkText} ></a>
    </div>
  )
}

const Button = ({ text, link }) => {
  return (
    <a href={link} title={text} target="_blank">
      <div className="border-2 border-white rounded-3xl px-3 py-1 mr-2 text-white w-max inline">
        {text}
      </div>
    </a>
  )
}

const BlueButton = ({ text, link }) => {
  return (
    <a href={link} title={text} target="_blank">
      <div className="text-sm bg-blue-600 rounded-3xl px-3 py-1 text-white w-max">
        {text}
      </div>
    </a>
  )
}

export default IndexPage
